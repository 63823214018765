import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable()
export class HttpService {

  private httpOptions = {
    baseUrl: environment.baseUrl,
    header: {
      headers: new HttpHeaders({
        // Authorization: this.authGuardService.getToken() ? this.authGuardService.getToken() : ''
        // Authorization: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhaSI6ImdBQUFBQUJkcC13aXd3Z1RRVUxrSlMyX1FoNzVraTZQNjR4d25xTEszWHNPT0piODJZeVJjYlJRbGJFWmgyZkhfekpnV0hSRDJuZWpoaHF4LVRfLWR6cy05MlBuck83czVRPT0iLCJiaSI6ImdBQUFBQUJkcC13aUVoWkhHN0xNcFBsWHcxb3BLUGdaaUVlRXlFU1dmVnFBaDRtX1JlUUs5U3RpMC1uY2EtSkZnWDA0anVMbzRBMlFQaThBcDNSOUNvS2xubE9jVDhXUlNGWVNTVzh5bS1pdExBaTFERXExV3JLLTl4M280YWN4OWJfT1RyQXNjckhFSVdzZ3hwR20wVUM5cC1qSDNjXzFRelNUeXV2SG00dnZEcmtPOGdUYnBuOD0ifQ.UJk25KU-rplNKfXkPKe9LnwGI1P2qY-vFQgQKsAd7VE'
      })
    }
  };
  constructor(
    private http: HttpClient,
    // private authGuardService: AuthGuardService,
    // private snackBar: MatSnackBar
  ) { }
  //  showSnackBar(message) {
  //   this.snackBar.open(message, '', {
  //     duration: 2000,
  //     verticalPosition: 'top',
  //     horizontalPosition: 'right'
  //   });
  // }

  get = (api: string) => {
    return this.http
      .get(this.httpOptions.baseUrl + api, this.httpOptions.header)
      .pipe(
        catchError(err => {
          console.log(err);
          return throwError(err);
        })
      );
  }

  post = (api: string, data) => {
    return this.http
      .post(this.httpOptions.baseUrl + api, data, this.httpOptions.header)
      .pipe(
        catchError(err => {
          return throwError(err);
        })
      );
  }

  put = (api: string, data) => {
    return this.http
      .put(this.httpOptions.baseUrl + api, data, this.httpOptions.header)
      .pipe(
        catchError(err => {
          return throwError(err);
        })
      );
  }

  delete = (api: string) => {
    return this.http
      .delete(this.httpOptions.baseUrl + api)
      .pipe(
        catchError(err => {
          return throwError(err);
        })
      );
  }
}

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // baseUrl: 'http://192.168.2.140:8000/api/',
  baseUrl: "https://dev-api.trvlbud.com/api/",
  // baseUrl: 'https://staging.trvlbud.com/api/',
  // baseUrl: 'https://bed.trvlbud.com/api/',
  
  // s3BucketConfig: {
  //   accessKeyId: 'AKIA3EKUURD5IBJNC7HJ',
  //   secretAccessKey: '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',
  //   region: 'ap-south-1'
  //   // region: 'us-east-1'
  // },

  azureConfig:{
    storageAccountName:'trvlbuddev',
    containerName:'trvlbuddev',
    //connectionString: 'DefaultEndpointsProtocol=https;AccountName=trvlbuddev;AccountKey=uNHQZ6W4ZWq7X7n45eqzZ5WGS2vccCDM8beJkVJXtIFBQ6gOieRi1oosoMfLtBM7BzPAGIbrxay0+AStkr4r/A==;EndpointSuffix=core.windows.net',
    sasToken: 'sp=racwdli&st=2023-06-21T13:07:42Z&se=2029-12-06T21:07:42Z&spr=https&sv=2022-11-02&sr=c&sig=UjmPAuvewJ4FnaB%2FIrCjm5O68GHIQ2zrqtvDVyQKjAI%3D', 
},
  // for DEV
  SECRET_CIPHER_KEY: '0123456789123456',
  // SECRET_CIPHER_KEY: '25Kq%341*YPLqReS',
  // for places api, dev server
  // googleApiKey: 'AIzaSyCiiLODIK4DQqxteYb74FrvMRqsTPKnH0M',
  googleApiKey:'AIzaSyA3QG7yNV_Yxn2HySJ8Wu6yNQie0qyP400',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.


import { BrowserModule } from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';

import { AuthGuard } from './authentication/auth-guard';

import { AuthGuardService } from './authentication/auth-guard.service';
import { HttpInterceptorService } from './shared/services/http-interceptor.service';
import { HttpService } from './shared/services/http.service';


@NgModule({
  declarations: [
    AppComponent,
    
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true
    },
    HttpService,
    AuthGuardService,
    AuthGuard,
  ],
 
  bootstrap: [AppComponent]
})
export class AppModule { }

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable()
export class AuthGuardService {

  constructor(
    private myRoute: Router
    ) { }

  // set token value into local storage
  sendToken(token: string) {
    localStorage.setItem('adminToken', token);
  }

  // get token value
  getToken() {
    return localStorage.getItem('adminToken');
  }

  // check whether user is loagged in or not
  isLoggednIn() {
    return this.getToken() !== null;
  }

  // log from applicaion and redirect to login page
  logout() {
    // localStorage.clear();
    localStorage.removeItem('adminToken');
    this.myRoute.navigate(['/']);
  }
}